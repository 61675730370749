/*! =========================================================
 *
 * Material Kit Free - v2.0.0 (Bootstrap 4 Edition)
 *
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-pro
 * Available with purchase of license from https://www.creative-tim.com/product/material-kit-pro
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * License Creative Tim (https://www.creative-tim.com/license)
 *
 * ========================================================= */

@import "core/variables";
@import "core/mixins";
@import "core/core-bootstrap";
@import "core/reboot";

// Core Components
@import "core/layout";
@import "core/buttons";
@import "core/misc";
@import "core/checkboxes";
@import "core/cards";
@import "core/custom-forms";
@import "core/switches";
@import "core/radios";
@import "core/carousel";
@import "core/forms";
@import "core/input-group";
@import "core/list-group";
@import "core/nav";
@import "core/images";
@import "core/navbar";
@import "core/badges";
@import "core/alerts";
@import "core/pagination";
@import "core/pills";
@import "core/info-areas";
@import "core/type";
@import "core/tabs";
@import "core/tooltip";
@import "core/popover";
@import "core/modal";
@import "core/dropdown";
@import "core/drawer";
@import "core/progress";
@import "core/togglebutton";
@import "core/ripples";
@import "core/headers";
@import "core/footers";

// Core Plugins

@import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";

// Sections and Example pages

@import "core/example-pages";
@import "core/responsive";
